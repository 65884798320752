import $ from 'jquery';


import 'flickity-bg-lazyload';
import '@fancyapps/fancybox';
import 'lazysizes';

window._Cookies = require('js-cookie');
require('jquery-pjax');



// JFM: Prototype-only code below
// --------------------------------------------------------------

function setFindUILabel(label) {
    $('.js-find-ui-input').attr('placeholder', $('.js-find-ui-input').data(label));
}

function closeFindUI() {
    $('body').removeClass('find-ui-is-active');
    $('.js-find-ui-input').val("");
    setFindUILabel('label-initial');

    $('.js-find-ui-categories').removeClass('hidden');
    $('.js-find-ui-results').addClass('hidden');
}

function showIntialServicePanel() {
    $('body').addClass('find-ui-is-active');

    document.querySelector('#find_ui').scrollIntoView({
        behavior: 'smooth'
    });

    setFindUILabel('label-active');
}


/**
 * to return dom object which contins string
 */
$.extend($.expr[":"], {
    "containsIN": function (elem, i, match, array) {
        return (elem.textContent || elem.innerText || "").toLowerCase().indexOf((match[3] || "").toLowerCase()) >= 0;
    }
});

/***
 * REALLY GOOD FUNCTION This function find text in string and make that string block without replacing string
 * @param textToCheck
 * @param searchTerm
 * @returns {*|Quoted|void|string|never}
 */
function myTag(textToCheck, searchTerm) {
    var searchPattern = new RegExp('(' + searchTerm + ')', 'ig');
    return textToCheck.replace(searchPattern, '<span class="text-primary">$1</span>');
}

/**
 *
 */
function showAutocompletePanel() {

    if ($(this).val() != "") {

        const searchedString = $(this).val();
        const servicesSource = $(document).find(".js-service:containsIN('" + $(this).val() + "')");

        // search services as we listed them already no need for ajax and prepare search result HTML
        let searchResultString = `
        <ul class="find-ui__choose-results-items list-reset flex flex-wrap">
        ${(servicesSource.toArray() === undefined || servicesSource.toArray().length == 0) ? `<li class="find-ui__choose-result-item w-12 md:w-6 p-1">
                    <div class="find-ui__choose-result-link bg-grey text-body flex items-center p-4 rounded hover:bg-primary-lighter">
                        <span>Sorry, No result found.</span>
                    </div>
                </li>` : ` 
            ${servicesSource.toArray().map((item, i) => `
                <li class="find-ui__choose-result-item w-12 md:w-6 p-1">
                    <a href="${$(item).data('slug')}" class="find-ui__choose-result-link bg-grey text-body flex items-center p-4 rounded hover:bg-primary-lighter">
                        <span class="heading-icon heading-icon--small heading-icon--${$(item).data('icon')} flex-none mr-4"></span>
                        <span> ${myTag($(item).data('title'), searchedString)} </span>
                    </a>
                </li>
                `).join('')}`}
        </ul>`;

        //replace search result HTML
        $('.js-find-ui-results').html(searchResultString);

        $('.js-find-ui-categories').addClass('hidden');
        $('.js-find-ui-results').removeClass('hidden');
    } else {
        $('.js-find-ui-categories').removeClass('hidden');
        $('.js-find-ui-results').addClass('hidden');
    }
}

//add simple support for background images:
document.addEventListener('lazybeforeunveil', function (e) {
    var bg = e.target.getAttribute('data-bg');
    if (bg) {
        e.target.style.backgroundImage = 'url(' + bg + ')';
    }
});

$(document).ready(function () {
        // show initial services panel
        $('.js-find-ui-input').on('focus click', showIntialServicePanel);

        // show autocomplete panel
        $('.js-find-ui-input').on('keyup keydown', showAutocompletePanel);

        // close services panel if clicking outside
        $(document).on('click', function (event) {
            if ($('.find-ui-is-active').length && !$(event.target).closest('.js-find-ui-element').length) {
                closeFindUI();
            }
        });

        // close services panel
        $('.js-find-ui-close').on('click', function () {
            closeFindUI();
        });

        $(".js-mainnav-toggle").on('click', function () {
            $('body').toggleClass('mainnav-is-active');
            return false;
        });

        // -----------------------------------------------------------------------------
        // Main nav - level 2 toggle
        // -----------------------------------------------------------------------------
        $(document).on('click', '.js-nav-level2-toggle', function (ev) {
            ev.preventDefault();
            ev.stopPropagation();

            var $this = $(this);
            var $nav = $this.parent().find('.dropdown-nav');

            $this.toggleClass('is-active');

            $('.dropdown-nav').not($nav).removeClass('is-active');
            $nav.toggleClass('is-active');
        });

        // Pop up form for quote and request a callback handle
        if ($.support.pjax) {
            $.pjax.defaults.timeout = 3000;
            $.pjax.defaults.push = false;

            $(document).on('submit', '.js-pjax-submit-form', function (event) {
                $.pjax.submit(event, {
                    scrollTo: false,
                    container: '.js-pjax-form-container',
                    fragment: '.js-pjax-form-container',
                });
            });

            $('.js-pjax-form-container').on('pjax:send', function (xhr, options, event) {
                $(".js-pjax-form-container").html("<div class='loading-spinner'><i></i><i></i><i></i></div>");
            });

            $('.js-pjax-form-container').on('pjax:complete', function (xhr, options, event) {
                recaptchaLoad();
            });

            $(document).on('submit', '.js-pjax-submit-request-form', function (event) {
                $.pjax.submit(event, {
                    scrollTo: false,
                    container: '.js-pjax-request-form-container',
                    fragment: '.js-pjax-request-form-container',
                });
            });

            $('.js-pjax-request-form-container').on('pjax:send', function (xhr, options, event) {
                $(".js-pjax-request-form-container").html("<div class='loading-spinner'><i></i><i></i><i></i></div>");
            });

        } else {

            // Quote form submission should open popup automatically
            if (window.location.hash == "#get-a-quote") {
                $(".js-get-a-quote").click();
            }

            // Quote form submission should open popup automatically
            if (window.location.hash == "#request_callback") {
                $(".js-request-a-callback").click();
            }

        }

        // Sticky numbers module

        if ($('.js-sticky-number-val').html() && $('.js-sticky-number-val').data("token")) {
            window._Cookies.set('stickyNumberToken', $('.js-sticky-number-val').data("token"));
        }

        if ($('.js-sticky-number-val').html() && $('.js-sticky-number-val').data("msn")) {
            window._Cookies.set('stickyMSN', $('.js-sticky-number-val').data("msn"));
        }

        if (typeof (window._Cookies.get('stickyNumberToken') !== "undefined")) {

            var phone = $(document).find('*[data-token="' + window._Cookies.get('stickyNumberToken') + '"]').html();
            //console.log('phone', phone);
            var msn = $(document).find('*[data-token="' + window._Cookies.get('stickyNumberToken') + '"]').data('msn');
            var memberName = $(document).find('*[data-token="' + window._Cookies.get('stickyNumberToken') + '"]').data('member');
            var memberPhoneOnTop = $(document).find('*[data-token="' + window._Cookies.get('stickyNumberToken') + '"]').data('phone-ontop');
            if (phone) {
                // global nsn
                if (msn != '' && memberPhoneOnTop == '1' && msn != memberName) {
                    //console.log('showontop on member(not global nsn)');
                    // member show on top
                } else if (msn == '') {
                    //console.log('global nsn, update phone and not set form name');
                    // global
                    $(".js-sticky-number").each(function (key, value) {
                        if ($(value).prop("tagName") == "A") {
                            $(value).html(phone);
                            $(value).attr('href', 'tel:' + $.trim(phone));
                            $(value).attr('title', 'Phone ' + phone);
                        } else {
                            $(value).html(phone);
                        }
                    });

                    $(".js-member-name").remove();
                    $("[id$=_memberName]").val('');
                } else if (msn) {
                    //console.log('member msn');
                    if (memberName) {
                        //console.log('on member page');
                        if (!memberPhoneOnTop && msn != memberName) {
                            //console.log('show on top not checked, show generic phone and no member name in form');
                        } else if (msn == memberName) {
                            //console.log('this is the MSN real page!')
                            $(".js-sticky-number").each(function (key, value) {
                                if ($(value).prop("tagName") == "A") {
                                    $(value).html(phone);
                                    $(value).attr('href', 'tel:' + $.trim(phone));
                                    $(value).attr('title', 'Phone ' + phone);
                                } else {
                                    $(value).html(phone);
                                }
                            });

                            $(".js-member-name").html(msn);
                            $("[id$=_memberName]").val(msn);
                        }
                    } else {
                        //console.log('not on member page, update phone');
                        $(".js-sticky-number").each(function (key, value) {
                            if ($(value).prop("tagName") == "A") {
                                $(value).html(phone);
                                $(value).attr('href', 'tel:' + $.trim(phone));
                                $(value).attr('title', 'Phone ' + phone);
                            } else {
                                $(value).html(phone);
                            }
                        });

                        if (msn == memberName && !memberPhoneOnTop) {
                            //console.log('show member name('+ memberName +') in form')
                            $(".js-member-name").html(memberName);
                            $("[id$=_memberName]").val(memberName);
                        } else {
                            //console.log('show member name('+ msn +') in form')
                            $(".js-member-name").html(msn);
                            $("[id$=_memberName]").val(msn);
                        }
                    }

                }
            }
        }
        
      // -----------------------------------------------------------------------------
      // Content row - toggle extended content
      // -----------------------------------------------------------------------------
      $('.js-extended-content').hide();
      
      $('.js-extend-content-show').on('click', function(){
        $(this).hide();
        $(this).siblings('.js-extended-content').show();
        $(this).siblings('.js-extend-content-hide').show();
      });
      
      $('.js-extend-content-hide').on('click', function(){
        $(this).hide();
        $(this).siblings('.js-extended-content').hide();
        $(this).siblings('.js-extend-content-show').show();
      });
     
        // Listener to main contact form selector
        $(document).on("change", ".js-select-form-select", function() {
            $(".js-loading").removeClass("hidden");
            $(".js-seconday-form").addClass("hidden");
            if($(this).val() != "") {
                $("#js-select-form").submit();
            }
        });

        // Change placeholder color on selects after selection
        $(document).on("change", ".select--contact", function() {
            $(this).removeClass('select--disabled-selected');
        });

    }
);


// -----------------------------------------------------------------------------
// Google recapture START
// -----------------------------------------------------------------------------
var existingRecaptcha = {};

var recaptchaInit = function () {
    var allRecaptcha = document.querySelectorAll('.recaptcha');
    var i = 0,
        c = allRecaptcha.length;
    for (i = 0; i < c; i += 1) {
        var el = allRecaptcha[i];
        var id = el.getAttribute('id');
        var sitekey = el.getAttribute('sitekey');

        if (existingRecaptcha.hasOwnProperty(id) && el.childNodes.length > 0) {
            grecaptcha.reset(existingRecaptcha[id]);
        } else {
            existingRecaptcha[id] = grecaptcha.render(el, {
                "sitekey": sitekey
            });
        }
    }
};

var recaptchaLoad = function () {
    // load re-captcha
    var recaptchaScript = document.createElement('script');
    recaptchaScript.async = true;
    recaptchaScript.src = 'https://www.google.com/recaptcha/api.js?onload=recaptchaInit&render=explicit';
    document.querySelector('head').appendChild(recaptchaScript);
};

window.recaptchaInit = recaptchaInit;

$(document).ready(function ($) {
    recaptchaLoad();
    
    
});
// -----------------------------------------------------------------------------
// Google recapture END
// -----------------------------------------------------------------------------


var a = 0;
$(window).scroll(function() {

  var oTop = $('#counter').offset().top - window.innerHeight;
  if (a == 0 && $(window).scrollTop() > oTop) {
    $('.counter-value').each(function() {
      var $this = $(this),
        countTo = $this.attr('data-count');
      $({
        countNum: $this.text()
      }).animate({
          countNum: countTo
        },

        {

          duration: 2000,
          easing: 'linear',
          step: function() {
            $this.text(Math.floor(this.countNum).toLocaleString('en-GB'));
          },
          complete: function() {
            $this.text(Math.floor(this.countNum).toLocaleString('en-GB'));
          }

        });
    });
    a = 1;
  }

});
